import React from 'react';
import styled from 'styled-components';

type DividerProps = {
  fill: string;
};

const Divider1 = ({ fill }: DividerProps) => (
  <svg viewBox="0 0 2000 50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill}
      d="m0 26.467 15.625-2.594 15.625-.394 15.625-1.881L62.5 22.74 78.125 23l15.625-4.797 15.625 2.896L125 20.196l15.625-1.05 15.625-4.086 15.625-.75 15.625 1.383 15.625 3.27 15.625.505 15.625-2.045 15.625.639 15.625-.43 15.625-4.039 15.625 1.54 15.625-1.811 15.625 2.458 15.625-.503 15.625 1.488L375 16.141l15.625.737 15.625-1.37 15.625 2.771 15.625.173 15.625-3.153 15.625 1.244 15.625 2.568L500 18.57l15.625-2.3 15.625.214 15.625-1.192 15.625-.061 15.625-1.528 15.625 1.827 15.625-.286L625 15.24l15.625-1.132 15.625.222 15.625 1.333L687.5 14.8l15.625-.04 15.625 3.304 15.625 2.612 15.625.373 15.625 2.102 15.625-3.136 15.625-2.741 15.625 2.076 15.625 1.37 15.625.55 15.625 2.039 15.625.4 15.625-.925 15.625.59 15.625.083 15.625-2.49 15.625-.309 15.625-.501 15.625-.243L1000 22.7l15.625 1.946 15.625-3.002 15.625.53 15.625-.093 15.625-.266 15.625.05 15.625.296L1125 24.18l15.625-.572 15.625.558 15.625-.98 15.625 2.695 15.625.215 15.625-.422 15.625-4.055 15.625.312 15.625-.772 15.625 3.086 15.625-2.128 15.625.892 15.625-1.954 15.625 2.12 15.625-2.992L1375 18.982l15.625 1.26 15.625-.278 15.625 1.67 15.625-2.191 15.625 1.072 15.625-.263 15.625 2.179L1500 23.947l15.625-1.438 15.625.475 15.625 2.68 15.625-.056 15.625-.196 15.625 1.728 15.625-.302L1625 23.732l15.625 3.328 15.625-1.817 15.625-3.138 15.625 1.357 15.625-.325 15.625-2.907 15.625-2.297 15.625 1.04 15.625.267 15.625-.063 15.625-1.562 15.625 2.988 15.625.205 15.625.5 15.625.48 15.625-.951 15.625 2.691 15.625-.605 15.625-.208 15.625-1.393 15.625-1.817 15.625-.37 15.625-.341L2000 23.33V50H0Z"
    />
  </svg>
);
const Divider2 = ({ fill }: DividerProps) => (
  <svg viewBox="0 0 2000 50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill}
      d="m0 37.079 15.625-.34 15.625-5.092 15.625 1.625 15.625-.936 15.625-.207 15.625-.889 15.625 3.4L125 33.436l15.625-1.962 15.625 1.09 15.625-.703L187.5 30.02l15.625-1.142 15.625 1.52 15.625.99 15.625-.34 15.625 4.083 15.625-.475 15.625-1.737 15.625 2.56 15.625-1.263 15.625-1.397 15.625-1.571L375 33.458l15.625-4.035 15.625-.26 15.625-2.444 15.625-.393 15.625 1.152 15.625-.223 15.625-1.162 15.625.013 15.625-1.666 15.625.024 15.625 2.662 15.625 2.17 15.625-.718 15.625.437 15.625 1.419 15.625.764 15.625.276 15.625-.804 15.625-.976 15.625-.039 15.625-2.944 15.625 1.584 15.625.728L750 32.047l15.625-1.511 15.625 2.642 15.625.156 15.625-1.306 15.625-1.19 15.625-.803 15.625 1.523 15.625.025 15.625 2.902 15.625-.616 15.625-2.61 15.625.982 15.625-1.9 15.625 2.725 15.625-.414L1000 29.553l15.625-3.091 15.625.08 15.625 1.647 15.625.942 15.625.163 15.625-2.925 15.625 1.686L1125 29.48l15.625-3.72 15.625.251 15.625-1.673 15.625 2.876 15.625 1.204 15.625 3.522 15.625.189 15.625-.07 15.625 1.46 15.625-.993 15.625 2.407 15.625-2.46 15.625-2.404 15.625-1.03 15.625-2.47L1375 28.525l15.625-2.777 15.625 2.268 15.625-2.05 15.625 1.935 15.625-.553 15.625-3.44 15.625-.134L1500 26.281l15.625-2.244 15.625 1.61 15.625.874 15.625-1.45 15.625-.404 15.625.648 15.625.79L1625 30.269l15.625-1.85 15.625 1.68 15.625 1.515 15.625-1.342 15.625-1.937 15.625.597 15.625-.271L1750 32.498l15.625.5 15.625-.329 15.625.943 15.625-1.105 15.625 1.83 15.625 1.042 15.625-3.292 15.625-.27 15.625-.443 15.625-3.249 15.625-.661 15.625-1.034 15.625-1.565 15.625 2.356 15.625-.51L2000 28.72V50H0Z"
    />
  </svg>
);

const Divider3 = ({ fill }: DividerProps) => (
  <svg viewBox="0 0 2000 50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill}
      d="m0 33.753 15.625 1.988 15.625-3.15 15.625 2.397L62.5 36.015l15.625-4.18 15.625-.316 15.625-1.525L125 33.409l15.625-2.258 15.625.533 15.625-1.957 15.625 1.117 15.625-.427 15.625 2.781 15.625-1.018L250 36.31l15.625.761 15.625-1.086 15.625 2.445 15.625.671 15.625.214 15.625 2.42 15.625 1.42L375 39.41l15.625-.56 15.625.241 15.625-.169 15.625-3.384 15.625 1.006 15.625-1.505 15.625 1.17L500 37.471l15.625-1.252 15.625.187 15.625 3.412 15.625 1.076 15.625.205 15.625-1.036 15.625.456L625 38.173l15.625 2.351 15.625-1.211 15.625-1.053 15.625-2.806 15.625-.202 15.625 3.057 15.625-1.028L750 37.068l15.625-1.522 15.625.84 15.625-1.461 15.625-2.903 15.625 1.88 15.625 1.163 15.625 1.656L875 34.638l15.625-.665 15.625-1.335 15.625 4.137 15.625-.487 15.625 2.74 15.625-1.778 15.625-2.29L1000 32.144l15.625-.766 15.625-.822 15.625-.1 15.625 2.975 15.625-.222 15.625-2.406 15.625-3.36 15.625 1.65 15.625 1.8 15.625-.606 15.625 1.648 15.625 2.66 15.625-1.636 15.625.954 15.625-2.17L1250 32.98l15.625 2.344 15.625-.196 15.625.759 15.625-1.829 15.625-4.51 15.625.213 15.625.238L1375 31.753l15.625-.632 15.625.773 15.625-2.799 15.625 1.633 15.625-2.958 15.625 1.93 15.625 3.457L1500 34.365l15.625-2.404 15.625-2.52 15.625-.99 15.625 2.06 15.625 2.582 15.625-.642 15.625-2.266L1625 32.27l15.625 3.168 15.625-1.946 15.625-.59 15.625-.763 15.625 3.011 15.625 1.045 15.625-1.228 15.625 3 15.625 1.81 15.625 1.601 15.625-1.458 15.625.18 15.625-2.435 15.625-.46 15.625-3.364 15.625.72 15.625-.025 15.625 1.143 15.625-3.592 15.625-.606 15.625-.586 15.625-.096 15.625 3.527L2000 36.1V50H0Z"
    />
  </svg>
);

const Divider4 = ({ fill }: DividerProps) => (
  <svg viewBox="0 0 2000 50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill}
      d="m0 33.93 15.625 2.046 15.625-.858 15.625-.316 15.625 2.13 15.625-1.426 15.625-2.543 15.625.814 15.625-.32 15.625-.014 15.625-2.422 15.625 3.29 15.625-.7 15.625 1.79 15.625-.988 15.625.392L250 39.417l15.625-.354 15.625-2.999 15.625-.641 15.625-.62 15.625 2.9 15.625-.448 15.625 1.19L375 37.349l15.625.622 15.625-4.562 15.625 1.351 15.625.108 15.625.734 15.625-3.769 15.625.071L500 32.99l15.625 3.04 15.625 2.868 15.625 1.882 15.625-2.284 15.625-.442 15.625 1.55 15.625.131L625 37.583l15.625 2.775 15.625-1.321 15.625-3.652 15.625.229 15.625.386 15.625 2.146 15.625-2.683L750 34.39l15.625-.955 15.625.16 15.625.915 15.625-1.282 15.625 1.547 15.625.22 15.625-2.181 15.625.101 15.625-.762 15.625-1.906 15.625-1.12 15.625-.7 15.625 1.104 15.625-1.744 15.625-1.471 15.625-.52 15.625.395 15.625-.014 15.625 2.789 15.625.858 15.625-1.797 15.625 3.397 15.625.621L1125 28.118l15.625 1.359 15.625-2.542 15.625 1.265 15.625 1.876 15.625-.797 15.625-4.322 15.625.085 15.625.594 15.625-3.116 15.625 1.235 15.625-1.305 15.625-2.977 15.625 1.336 15.625-1.296 15.625.403 15.625.202 15.625 2.784 15.625 1.507 15.625-1.355 15.625 1.103 15.625-2.425 15.625-.822 15.625-1.601L1500 21.424l15.625.577 15.625-1.798 15.625 1.115 15.625.784 15.625-1.344 15.625-2.039 15.625-.54L1625 20.59l15.625 1.56 15.625-3.396 15.625-1.175 15.625.414 15.625-.592 15.625 4.222 15.625 1.078 15.625-.058 15.625-3.313 15.625 1.59 15.625-3.554 15.625.358 15.625-2.819 15.625 2.08 15.625.781L1875 19.192l15.625-.458 15.625-.173 15.625 2.285 15.625-1.657 15.625-1.688 15.625 1.628 15.625-.729 15.625.501V50H0Z"
    />
  </svg>
);

const DividerWrapper = styled.div`
  bottom: 99%;
  position: absolute;
  width: 100%;

  & svg {
    display: block;
    height: 4.2rem;
    min-width: 1000px;
    width: 100%;
  }
`;

const Dividers = (props: { children: React.ReactNode }): JSX.Element => (
  <DividerWrapper role="img" aria-hidden="true">
    {props.children}
  </DividerWrapper>
);

Dividers.Divider1 = Divider1;
Dividers.Divider2 = Divider2;
Dividers.Divider3 = Divider3;
Dividers.Divider4 = Divider4;

export default Dividers;
