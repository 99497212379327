module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-instagram-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#B35900","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"packup","description":"Adventure made easy. Pack with confidence with a trip generator for any occasion, create and share collaborative packing lists, and learn from others and view the trips they packed for.","short_name":"packup","lang":"en","start_url":"/","background_color":"#f3f3f3","theme_color":"#0b2b44","orientation":"portrait","categories":["packing","outdoors","productivity","safety"],"shortcuts":[{"name":"Add New Trip","short_name":"New Trip","description":"Quickly create a new trip","url":"/app/trips/new","icons":[{"src":"/img/shortcut-add-trip.png","sizes":"192x192"}]},{"name":"View All Trips","short_name":"All Trips","description":"View the list of all your trips","url":"/app/trips","icons":[{"src":"/img/shortcut-trips.png","sizes":"192x192"}]},{"name":"View My Profile","short_name":"My Profile","description":"View and Edit My Profile","url":"/app/profile","icons":[{"src":"/img/shortcut-profile.png","sizes":"192x192"}]}],"display":"standalone","icon":"src/images/maskable_icon.png","icon_options":{"purpose":"any maskable"},"url_handlers":[{"origin":"https://getpackup.com"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ed499377bf4acd3b54ed8182bb1dc84b"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":"<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>","offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"publicPath":"cms","modulePath":"/opt/build/repo/src/cms/cms.ts"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"B71zbFftEBDUWzlWZGbmodyzgBjTSnPv","devKey":"","trackPage":true,"trackPageDelay":50,"delayLoad":true,"delayLoadTime":1000},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://6f10b1a86af948eab1b6e943861e0cf8@o456660.ingest.sentry.io/5449967"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-177199635-1","cookieName":"packup-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-5JBLQWK","cookieName":"packup-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"packup-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
